import React, { useContext , useState,useEffect} from 'react';
import { Grid, Container, Paper, Box, Stack ,FormControl,MenuItem,InputLabel,Select, Typography} from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { GEHerdEvaluationDistributionContext } from './../Context/GEHerdEvaluationDistributionContext';

import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';
import HeaderDictionary from '../Helper/HeaderDictionary';


const DistributionComparsion = (props) => {


  const { DistributionIndexSelect,
          DistributionIndexList,
          DistributionYearSelect,
          DistributionYearList,
          DistributionLacSelect,
          DistributionLacList,
          DistributionDataset,
          handleChangeDistributionIndex,
          handleChangeDistributionYear,
          handleChangeDistributionLactation,
          setHerdIdEvalDistribution,
          evalDate

    } = useContext(GEHerdEvaluationDistributionContext);
    
    setHerdIdEvalDistribution(props.HerdId);

    var evalD = evalDate;
    var index = DistributionIndexSelect;
    var lact= DistributionLacSelect;
    var year = DistributionYearSelect;

    const tableData = DistributionDataset;

    if(year == "ALL"){
      year = "";
    }

    if(lact == "ALL"){
      lact = "";
    }

    const [chartDataTableOpen,setChartDataTableOpen] = useState('');

    const exportToExcel = () => {

        console.log("exccel");

        const table = document.getElementById('NormalDistributionTable');
        const worksheet = utils.table_to_sheet(table);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        const excelBuffer = write(workbook, { type: 'array', bookType: 'xlsx' });
        const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(data, 'Herd_'+DistributionIndexSelect+'_Normal_Distribution_in_'+DistributionYearSelect+'_year_for_'+DistributionLacSelect+'.xlsx');
    };

    useEffect(() => {
        console.log("effff");
        const buttonElement = document.getElementById('NormalDistributionExport');
        if (buttonElement) {
        buttonElement.addEventListener('click', exportToExcel);
        console.log("done");
        }

    },[chartDataTableOpen]);

    let option = {};


    if(DistributionDataset != undefined){
      if(DistributionDataset.pdfDataSet.length !== 0){
        option = {
          title: {
            right:'right',
            text: HeaderDictionary.Headers[index]+' '+year +' '+lact+ ' توزیع نرمال  {a|            }',
            subtext: evalD+' ارزیابی ',
            textStyle:{
        
          
              rich: {
  
                a: {
                    backgroundColor: {
                        image: './logo.png'
                    },
                    height: 50
                },
  
                
              }
            }
          },
          toolbox: {
            left:'auto',
            show: true,
            feature: {
              dataZoom: {
                yAxisIndex: "none"
              },
              dataView: {
                readOnly: false,
                optionToContent: function(opt){
                  
                  setChartDataTableOpen(opt);

                  var table = "<button id='NormalDistributionExport'> Export excel </button>";

                  table += '<table style="width:100%;text-align:center;" id="NormalDistributionTable"><tbody><tr>'
                        + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">انحراف</th>'
                        + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">میانگین</th>'
                        + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">جمعیت</th>'
                        + '<th style="background:#1a512e;color:#fff;padding-top:10px;padding-bottom:10px;">درصد از جمعیت</th>'
                        + '</tr>'

                        +'<tr style="background:#AC3429">'
                        + '<td> Outlier (-) </td>'
                        + '<td>' + (tableData.deviationSlices[0]).toFixed(1) +' > mean </td>'
                        +'<td>' + tableData.deviationSlicesCount[0].populationCount + '</td>'
                        +'<td>' + (tableData.deviationSlicesCount[0].populationPercentage).toFixed(1)  + ' %</td>'
                        + '</tr>'


                        +'<tr style="background:#D5561B">'
                        + '<td> -3 </td>'
                        + '<td>' + (tableData.deviationSlices[0]).toFixed(1) +'</td>'
                        +'<td>' + tableData.deviationSlicesCount[1].populationCount + '</td>'
                        +'<td>' + (tableData.deviationSlicesCount[1].populationPercentage).toFixed(1)  + ' %</td>'
                        + '</tr>'


                        +'<tr style="background:#D86E35">'
                        + '<td> -2 </td>'
                        + '<td>' + (tableData.deviationSlices[1]).toFixed(1) +'</td>'
                        +'<td>' + tableData.deviationSlicesCount[2].populationCount + '</td>'
                        +'<td>' + (tableData.deviationSlicesCount[2].populationPercentage).toFixed(1)  + ' %</td>'
                        + '</tr>'


                        +'<tr style="background:#DF8B57">'
                        + '<td> -1 </td>'
                        + '<td>' + (tableData.deviationSlices[2]).toFixed(1) +'</td>'
                        +'<td>' + tableData.deviationSlicesCount[3].populationCount + '</td>'
                        +'<td>' + (tableData.deviationSlicesCount[3].populationPercentage).toFixed(1)  + ' %</td>'
                        + '</tr>'


                        +'<tr style="background:#E7EAEF">'
                        + '<td> 0 </td>'
                        + '<td>' + (tableData.deviationSlices[3]).toFixed(1) +'</td>'
                        +'<td> -- </td>'
                        +'<td> -- </td>'
                        + '</tr>'

                        +'<tr style="background:#67CA9A">'
                        + '<td> +1 </td>'
                        + '<td>' + (tableData.deviationSlices[4]).toFixed(1) +'</td>'
                        +'<td>' + tableData.deviationSlicesCount[4].populationCount + '</td>'
                        +'<td>' + (tableData.deviationSlicesCount[4].populationPercentage).toFixed(1)  + ' %</td>'
                        + '</tr>'


                        +'<tr style="background:#36A26E">'
                        + '<td> +2 </td>'
                        + '<td>' + (tableData.deviationSlices[5]).toFixed(1) +'</td>'
                        +'<td>' + tableData.deviationSlicesCount[5].populationCount + '</td>'
                        +'<td>' + (tableData.deviationSlicesCount[5].populationPercentage).toFixed(1)  + ' %</td>'
                        + '</tr>'

                        +'<tr style="background:#127143">'
                        + '<td> +3 </td>'
                        + '<td>' + (tableData.deviationSlices[6]).toFixed(1) +'</td>'
                        +'<td>' + tableData.deviationSlicesCount[6].populationCount + '</td>'
                        +'<td>' + (tableData.deviationSlicesCount[6].populationPercentage).toFixed(1)  + ' %</td>'
                        + '</tr>'

                        +'<tr style="background:#0984e3">'
                        + '<td> Outlier (+) </td>'
                        + '<td>' + (tableData.deviationSlices[6]).toFixed(1) +' < mean </td>'
                        +'<td>' + tableData.deviationSlicesCount[7].populationCount + '</td>'
                        +'<td>' + (tableData.deviationSlicesCount[7].populationPercentage).toFixed(1)  + ' %</td>'
                        + '</tr>'

                        
                      
                      
                  table += '</tbody></table>';
                  return table;
                }
              },
              saveAsImage: {
                name: index+' '+year +' '+lact+ ' توزیع نرمال'
              }
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '8%',
            top: '20%',
            containLabel: true
          },
          tooltip: {
            trigger: 'none',
            
          },
          visualMap: {
            type: 'piecewise',
            show: true,
            dimension: 0,
            seriesIndex: 0,
            top: '30%',
            orient: 'vertical',
            left: 'left',
            
            
            pieces: [
              //#2ecc71
              {
                lt: DistributionDataset.deviationSlices[0],
                color: '#AC3429',
                label: '- Outlier '+ (DistributionDataset.deviationSlicesCount[0].populationPercentage).toFixed(1) + "%"
              },
              {
                gt: DistributionDataset.deviationSlices[6],
                color: '#0984e3',
                label: '+ Outlier '+(DistributionDataset.deviationSlicesCount[7].populationPercentage).toFixed(1) + "%"
              },
              {
                gt: DistributionDataset.deviationSlices[0],
                lt: DistributionDataset.deviationSlices[1],
                color: '#D5561B',
                label: (DistributionDataset.deviationSlicesCount[1].populationPercentage).toFixed(1) + "%"
              },
              {
                gt: DistributionDataset.deviationSlices[1],
                lt: DistributionDataset.deviationSlices[2],
                color: '#D86E35',
                label: (DistributionDataset.deviationSlicesCount[2].populationPercentage).toFixed(1) + "%"
              },
              {
                gt: DistributionDataset.deviationSlices[2],
                lt: DistributionDataset.deviationSlices[3],
                color: '#DF8B57',
                label: (DistributionDataset.deviationSlicesCount[3].populationPercentage).toFixed(1) + "%"
              },
              {
                gt: DistributionDataset.deviationSlices[3],
                lt: DistributionDataset.deviationSlices[4],
                color: '#67CA9A',
                label: (DistributionDataset.deviationSlicesCount[4].populationPercentage).toFixed(1) + "%"
              },
              {
                gt: DistributionDataset.deviationSlices[4],
                lt: DistributionDataset.deviationSlices[5],
                color: '#36A26E',
                label: (DistributionDataset.deviationSlicesCount[5].populationPercentage).toFixed(1) + "%"
              }
              ,
              {
                gt: DistributionDataset.deviationSlices[5],
                lt: DistributionDataset.deviationSlices[6],
                color: '#127143',
                label: (DistributionDataset.deviationSlicesCount[6].populationPercentage).toFixed(2) + "%"
              },
              
            ]
          },
          xAxis: 
            [{
              type: 'value',
              // prettier-ignore
              boundaryGap: [0, '10%'],
              splitLine : false,
              axisLabel:false,
              axisTick: false,
              
              
             
            },
            
            
            
            
            ]
          ,
          yAxis: 
            {
              type: 'value',
              boundaryGap: [0, '10%'],
              splitLine : false,
              show:false
              
              
              
            }
          ,
          series: [
        
            {
              
              type: 'line',
              smooth: 0.6,
              symbol: 'none',
              lineStyle: {
                color: '#3498db',
              },
              markLine: {
                symbol: ['none', 'none'],
                label: { 
                  
                  show: true,
                  position:'start',
                  
                  formatter : function (params){
                    
                    
                    
                    var dev =  params.dataIndex-3;
                    var lbl = "";
                    if(dev <= 0){
                      lbl = params.value.toFixed(0) + "\n("+dev+")"
                    }
                    else{
                      lbl = params.value.toFixed(0) + "\n(+"+dev+")"
                    }
                    
                    
                    return lbl;
                  }
                    
                  
                  
                  
                  
                },
                
                
                data: [
                  { xAxis: DistributionDataset.deviationSlices[0] }, 
                      
                  { xAxis: DistributionDataset.deviationSlices[1] }, 
                      
                  { xAxis: DistributionDataset.deviationSlices[2] }, 
                      
                  { xAxis: DistributionDataset.deviationSlices[3] },
                      
                  { xAxis: DistributionDataset.deviationSlices[4] }, 
                      
                  { xAxis: DistributionDataset.deviationSlices[5] },
                      
                  { xAxis: DistributionDataset.deviationSlices[6] },
              
                ]
              },
              areaStyle: {},
              data: DistributionDataset.pdfDataSet
            }
            
          ]
        };
      }
      else{
        option = {
          title: {
            right:'right',
            text: HeaderDictionary.Headers[index]+' '+year +' '+lact+ ' توزیع نرمال  {a|            }',
            subtext: evalD+' ارزیابی ',
            textStyle:{
        
          
              rich: {
  
                a: {
                    backgroundColor: {
                        image: './logo.png'
                    },
                    height: 50
                },
  
                
              }
            }
          },
          toolbox: {
            left:'auto',
            show: true,
            feature: {
              dataZoom: {
                yAxisIndex: "none"
              },
              dataView: {
                readOnly: false,

              },
              saveAsImage: {
                name: index+' '+year +' '+lact+ ' توزیع نرمال'
              }
            }
          },
          grid: {
            left: '3%',
            right: '4%',
            bottom: '8%',
            top: '20%',
            containLabel: true
          },
          tooltip: {
            trigger: 'none',
            
          },
            visualMap: {
            type: 'piecewise',
            show: true,
            dimension: 0,
            seriesIndex: 0,
            top: '5%',
            orient: 'vertical',
            left: 'left',
            
            
            
          },
          xAxis: 
            [{
              type: 'value',
              // prettier-ignore
              boundaryGap: [0, '10%'],
              splitLine : false,
              axisLabel:false,
              axisTick: false,
              
              
             
            },
            
            
            
            
            ]
          ,
          yAxis: 
            {
              type: 'value',
              boundaryGap: [0, '10%'],
              splitLine : false,
              show:false
              
              
              
            }
          ,
          series: [
        
            {
              
              type: 'line',
              smooth: 0.6,
              symbol: 'none',
              lineStyle: {
                color: '#3498db',
              },
              markLine: {
                symbol: ['none', 'none'],
                
                
                
                data: [
                  
              
                ]
              },
              areaStyle: {},
              data: []
            }
            
          ]

        };
      }
      
      
    }

    

    return ( 

      <Grid container spacing={2} sx={{marginTop:5}} style={{direction:"ltr"}}>
        <Box component="div" style={{background:"#34495e",width:"100%",borderTopLeftRadius:15,borderTopRightRadius:15}}>
              <Grid sm={12} md={12} style={{width:"100%",display:"flex",justifyContent:"center",paddingTop:10,paddingBottom:10}}>
                <Typography variant="h6" color="#fff">
                    توزیع نرمال
                </Typography>
              </Grid>
              <Grid spacing={2} sm={12} md={12} style={{width:"100%",display:"flex",justifyContent:"space-between",background:"#fff",paddingTop:10}}>
                <FormControl style={{borderColor:"#fff",padding:5}} fullWidth >
                    <InputLabel id="demo-simple-select-label">شاخص</InputLabel>
                    <Select
                        
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={DistributionIndexSelect}
                        label="شاخص"
                        
                        onChange={handleChangeDistributionIndex}
                    >
                        
                        {
                          DistributionIndexList.map((val) => {
                            return (
                              <MenuItem style={{direction:'ltr'}} value={val} key={val}>{HeaderDictionary.Headers[val]}</MenuItem>
                            );
                          })
                        }
                        
                    </Select>
                </FormControl>
                

                <FormControl style={{borderColor:"#fff",padding:5}} fullWidth >
                    <InputLabel id="demo-simple-select-label">سال تولد</InputLabel>
                    <Select
                        
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={DistributionYearSelect}
                        label="سال تولد"
                        
                        onChange={handleChangeDistributionYear}
                    >
                        
                        {
                          DistributionYearList.map((val) => {
                            return(
                              <MenuItem value={val} key={val}>{val}</MenuItem>
                            );
                            
                          })
                        }
                    </Select>
                </FormControl>

                <FormControl style={{borderColor:"#fff",padding:5}} fullWidth >
                    <InputLabel id="demo-simple-select-label">گروه تولیدی</InputLabel>
                    <Select
                        
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={DistributionLacSelect}
                        label="گروه تولیدی"
                        
                        onChange={handleChangeDistributionLactation}
                    >
                        

                        {
                          DistributionLacList.map((val)=>{

                            return(
                              <MenuItem Key={val} value={val}>{val}</MenuItem>
                            );

                          })


                        }

                        
                    </Select>
                </FormControl>

                

              </Grid>
            </Box>
            <Grid md={12}>
                <Paper elevation={3} style={{height:600,paddingTop:5}}>
                    <ReactECharts option={option} style={{height:'100%'}}/>
                </Paper>
                <Paper elevation={3} style={{paddingTop:1,marginTop:2}}>
                  {tableData === undefined ? <></> :
                    <div style={{display:'flex',justifyContent:'space-around'}}>
                      <Typography variant='h6' component='h6'  style={{marginRight:10,marginTop:10}}>
                        Mean : {tableData.mean.toFixed(1)}
                      </Typography>

                      <Typography variant='h6' component='h6'  style={{marginRight:10,marginTop:10}}>
                        Median : {tableData.median.toFixed(1)}
                      </Typography>

                      <Typography variant='h6' component='h6'  style={{marginRight:10,marginTop:10}}>
                      Standard Deviation : {tableData.standardDeviation.toFixed(1)}
                      </Typography>

                      <Typography variant='h6' component='h6'  style={{marginRight:10,marginTop:10}}>
                      Variance : {tableData.variance.toFixed(1)}
                      </Typography>

                      <Typography variant='h6' component='h6'  style={{marginRight:10,marginTop:10}}>
                      CV : {tableData.cv.toFixed(1)}
                      </Typography>

                      <Typography variant='h6' component='h6'  style={{marginRight:10,marginTop:10}}>
                      Skewness : {tableData.skewness.toFixed(1)}
                      </Typography>
                    </div>
                    
                  }
                </Paper>
            </Grid>


      </Grid>
        

     );
}
 
export default DistributionComparsion;